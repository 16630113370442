import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { dispatch } from 'store'
import { orderDeliveryButton } from 'images'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://www.ubereats.com/vancouver/food-delivery/hyack-sushi-8th-street/a1rx1UN_QZumSq1UXLSt4Q`}
            >
              <CFView hover>
                <CFImage
                  src={orderDeliveryButton}
                  maxWidth="200px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://www.ubereats.com/vancouver/food-delivery/hyack-sushi-8th-street/a1rx1UN_QZumSq1UXLSt4Q`}
            >
              <CFView hover>
                <CFImage
                  src={orderDeliveryButton}
                  maxWidth="220px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
