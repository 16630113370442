import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { mobilePromotion, promotions } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobilePromotion} w="100%" alt="Promotions" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px" mt="30px">
          <CFImage src={promotions} w="80%" alt="Promotions" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
