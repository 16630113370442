import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import DownloadAppButton from './DownloadAppButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          center
        >
          <CFView textCenter column center w="100%">
            <CFView column center>
              <CFImage src={mobileHeroText} w="100%" alt="Promotions" />
            </CFView>
            <CFView mv="10px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView mb="10px">
              <OrderDeliveryButton />
            </CFView>
            <CFView>
              <DownloadAppButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          image={`url(${hero}) top/ 1400px no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <CFView w="100%" column justifyStart alignStart>
            <Header />
          </CFView>
          <CFView w="100%" column center h="600px">
            <CFView column center pt="30px">
              <CFImage src={heroText} w="55%" alt="Promotions" />
            </CFView>
            <CFView mt="30px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView mv="10px">
              <OrderDeliveryButton />
            </CFView>
            <CFView>
              <DownloadAppButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
